import { Controller} from "@hotwired/stimulus"
import { delegate, abnegate } from "jquery-events-to-dom-events"

export default class extends Controller {
  static targets = [ "start", "end"]

  connect() {
    if(this.element.dataset.delegate)
      this.delegate = delegate(this.element.dataset.delegate);
  }

  disconnect(){
    if(this.element.dataset.delegate)
      abnegate(this.element.dataset.delegate, this.delegate)
  }

  resetForm() {
    this.startTarget.value = this.startTarget.dataset.contracted;
    this.endTarget.value = this.endTarget.dataset.contracted;
  }
}


