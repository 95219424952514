import { Controller} from "@hotwired/stimulus"
import { delegate } from "jquery-events-to-dom-events"

function formatTime24(date)
{
  var hours = date.getHours();
  var minutes = date.getMinutes();
  hours = ('0' + hours).slice(-2);
  minutes = ('0' + minutes).slice(-2);

  return hours + ':' + minutes;
}

function setDateTimeFromString(date, timeString) {
	if (timeString == '') return null;

	var time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
	if (time == null) return null;

	var hours = parseInt(time[1],10);
	if (hours == 12 && !time[4]) {
		  hours = 0;
	}
	else {
		hours += (hours < 12 && time[4])? 12 : 0;
	}

	date.setHours(hours);
	date.setMinutes(parseInt(time[3],10) || 0);
	date.setSeconds(0, 0);
	return date;
}

export default class extends Controller {
  static targets = [ "confirmedStartDate", "confirmedStartTime", "confirmedEndDate", "confirmedEndTime", "timeline", "statistics"]

  get startTime(){
    return new Date(this.data.get("start-time"));
  }

  get endTime(){
    return new Date(this.data.get("end-time"));
  }

  get confirmedStartTime()
  {
    return setDateTimeFromString(this.startTime, this.confirmedStartTimeTarget.value);
  }

  get confirmedEndTime(){
    return setDateTimeFromString(this.endTime, this.confirmedEndTimeTarget.value);
  }

  updateTimeline()
  {
    var start_time = this.startTime;
    var end_time = this.endTime;
    var duration = end_time.valueOf() - start_time.valueOf();

    var confirmed_start_time = this.confirmedStartTime;
    var confirmed_end_time = this.confirmedEndTime;
    var confirmed_duration = confirmed_end_time.valueOf() - confirmed_start_time.valueOf();
    console.log("start " + start_time);
    console.log("end " + end_time);

    console.log("c start " + confirmed_start_time);
    console.log("c end " + confirmed_end_time);

    console.log("Duration " + duration);

    var progress_start = Math.min(start_time, confirmed_start_time);
    var progress_end = Math.max(end_time, confirmed_end_time);

    var progress_duration = progress_end.valueOf() - progress_start.valueOf();

    if(start_time > confirmed_start_time)
    {
      var overtime_duration = start_time.valueOf() - confirmed_start_time.valueOf();
      var percentage = Math.round(overtime_duration * 100.0 / progress_duration);
      this.timelineTarget.innerHTML = `<div class='timeline-bar bg-warning' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(confirmed_start_time)}</div></div>`;
    }

    if(start_time < confirmed_start_time)
    {
      var undertime_duration = confirmed_start_time.valueOf() - start_time.valueOf();
      var percentage = Math.round(undertime_duration * 100.0 / progress_duration);
      this.timelineTarget.innerHTML = `<div class='timeline-bar bg-white' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'></div>`;
    }

    var test_percentage = Math.round(duration * 100.0 / progress_duration);
    this.timelineTarget.innerHTML += `<div class='timeline-bar bg-primary' role='progressbar' style='width: ${test_percentage}%' aria-valuenow='${test_percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(start_time)}</div></div>`;

    if(end_time > confirmed_end_time)
    {
      var undertime_duration = end_time.valueOf() - confirmed_end_time.valueOf();
      var percentage = Math.round(undertime_duration * 100.0 / progress_duration);
      this.timelineTarget.innerHTML += `<div class='timeline-bar bg-danger' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(confirmed_end_time)}</div></div>`;
    }

    if(end_time < confirmed_end_time)
    {
      var overtime_duration = confirmed_end_time.valueOf() - end_time.valueOf();
      var percentage = Math.round(overtime_duration * 100.0 / progress_duration);
      this.timelineTarget.innerHTML += `<div class='timeline-bar bg-warning' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(end_time)}</div></div>`;
    }

    this.statisticsTarget.innerHTML += `<div>Contracted:${duration}</div>`;
    this.statisticsTarget.innerHTML += `<div>Worked:${confirmed_duration}</div>`;
  }

  connect() {
    console.log("working");
    delegate('changeTime');
  }

  input() {

  }

  hide()
  {
    this.updateTimeline();
  }
}


/*
function shift_timeline()
{
  var start_time = new Date('<%= @shift.start_time %>');
  var end_time = new Date('<%= @shift.end_time %>');
  var duration = end_time.valueOf() - start_time.valueOf();

  var confirmed_start_time = new Date('<%= @shift.confirmed_start_time %>');
  var confirmed_end_time = new Date('<%= @shift.confirmed_end_time %>');

  var progress_start = Math.min(start_time, confirmed_start_time);
  var progress_end = Math.max(end_time, confirmed_end_time);

  var progress_duration = progress_end.valueOf() - progress_start.valueOf();

  if(start_time > confirmed_start_time)
  {
    var overtime_duration = start_time.valueOf() - confirmed_start_time.valueOf();
    var percentage = Math.round(overtime_duration * 100.0 / progress_duration);
    document.getElementById("timeline").innerHTML = `<div class='timeline-bar bg-warning' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(confirmed_start_time)}</div></div>`;
  }

  if(start_time < confirmed_start_time)
  {
    var undertime_duration = confirmed_start_time.valueOf() - start_time.valueOf();
    var percentage = Math.round(undertime_duration * 100.0 / progress_duration);
    document.getElementById("timeline").innerHTML = `<div class='timeline-bar bg-white' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'></div>`;
  }

  var test_percentage = Math.round(duration * 100.0 / progress_duration);
  document.getElementById("timeline").innerHTML += `<div class='timeline-bar bg-primary' role='progressbar' style='width: ${test_percentage}%' aria-valuenow='${test_percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(start_time)}</div></div>`;

  if(end_time > confirmed_end_time)
  {
    var undertime_duration = end_time.valueOf() - confirmed_end_time.valueOf();
    var percentage = Math.round(undertime_duration * 100.0 / progress_duration);
    document.getElementById("timeline").innerHTML += `<div class='timeline-bar bg-danger' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(confirmed_end_time)}</div></div>`;
  }

  if(end_time < confirmed_end_time)
  {
    var overtime_duration = confirmed_end_time.valueOf() - end_time.valueOf();
    var percentage = Math.round(overtime_duration * 100.0 / progress_duration);
    document.getElementById("timeline").innerHTML += `<div class='timeline-bar bg-warning' role='progressbar' style='width: ${percentage}%' aria-valuenow='${percentage}' aria-valuemin='0' aria-valuemax='100'><div class="progress-value">${formatTime24(end_time)}</div></div>`;
  }
} */

