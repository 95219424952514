import { application } from "../controllers/application";
import { Controller } from "@hotwired/stimulus";

const component_controllers = import.meta.glob("../components/**/index.js", { eager: true })

for (let path in component_controllers) {
  let module = component_controllers[path];

  // Check whether a module has the Controller export defined
  if (typeof module.default === 'function' && module.default.prototype instanceof Controller) {
    const name = path.replace(/^\.\//, '')
      .replace(/\/index\.js$/, '')
      .replace(/_/g, '-')
      .replace(/\//g, '--');

    console.log(name);
    application.register(name, module.default);
  }
}
