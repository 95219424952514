import { Controller } from "@hotwired/stimulus";
import { get, post, put, patch, destroy } from "@rails/request.js";

//import '@fullcalendar/core/vdom';
import { Calendar } from '@fullcalendar/core';
import multiMonthPlugin from '@fullcalendar/multimonth'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';


export default class extends Controller {
  static targets = ["calendar"];

  static values = {
    events: String,
    baseUrl: String,
    date: String,
    view: String
  }

  connect() {
    let view = "timeGridWeek";

    switch (this.viewValue) {
      case "day":
        view = "timeGridDay"
        break;
      case "week":
        view = "timeGridWeek"
        break;
      case "month":
        view = "dayGridMonth"
        break;
      case "year":
        view = "multiMonthYear"
        break;
    }

    let base_url = this.baseUrlValue;

    this.calendar = new Calendar(this.calendarTarget, {
      plugins: [dayGridPlugin, timeGridPlugin, bootstrap5Plugin, interactionPlugin, multiMonthPlugin],
      initialView: view,
      headerToolbar: false,
      nowIndicator: true,
      businessHours: true,
      height: "100%",
      themeSystem: 'bootstrap5',
      eventDisplay: 'block',
      //contentHeight: '100%',
      initialDate: this.dateValue,
      events: JSON.parse(this.eventsValue),
      firstDay: 1,
      allDaySlot: false,
      editable: false,
      eventStartEditable: false,
      displayEventEnd: true,
      locale: nlLocale,
      selectable: true,

      dateClick: function (info) {
        // alert('Clicked on: ' + info.dateStr);
        // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        // alert('Current view: ' + info.view.type);
        // // change the day's background color just for fun
        // info.dayEl.style.backgroundColor = 'red';
      },
      eventClick: function (info) {

      },

      select: function (info) {
      },

      eventChange: function (info) {
      },
      viewDidMount(args) {

        document.getElementById('fc-separated-title').innerHTML = args.view.title;

        let date = args.view.calendar.getDate();

        let previous = date;
        let next = date;

        switch (args.view.type) {
          case "timeGridDay":
            previous = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
            next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
            break;
          case "timeGridWeek":
            previous = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
            next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
            break;
          case "dayGridMonth":
            previous = new Date(date.getFullYear(), (date.getMonth() - 1 % 12), date.getDate());
            next = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
            break;
          case "multiMonthYear":
            previous = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
            next = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
            break;
        }

        document.getElementById('fc-previous-button').dataset.setInputValueValue = previous.getFullYear() + "-" + (previous.getMonth() + 1) + "-" + previous.getDate();
        document.getElementById('fc-next-button').dataset.setInputValueValue = next.getFullYear() + "-" + (next.getMonth() + 1) + "-" + next.getDate();
      }
    });

    this.calendar.render();

  }
  disconnect() {
    this.calendar.destroy();
  }

  refresh(e) {
    if (e.detail.success) {
      this.calendar.refetchEvents();
    }
  }
}
