// app/javascript/controllers/ts/search_controller.js

import { Controller } from "@hotwired/stimulus"
import { get }        from "@rails/request.js"
import TomSelect      from "tom-select"

// Connects to data-controller="ts--search"
export default class extends Controller {
  static values = {
    url: String,
    selected: String,
    canCreate: Boolean,
    onCreateUrl: String,
    fetchOnConnect: Boolean,
    noResultsFoundText: String,
    createText: String
  }
  static outlets = ["preload"]

  connect() {
    this.tomSelect;
    if (this.fetchOnConnectValue)
      this.fetchItems();
  }

  test(){
    if (this.hasPreloadOutlet) {
      this.preloadOutlets.forEach( trigger => trigger.trigger(this.element.value) )
    }
  }

  trigger(value)
  {
    this.fetchItems(value)
  }

  async fetchItems(query = "") {
    const response = await get(this.urlValue, {
      query: { q: query },
      responseKind: 'json'
    })

    if (response.ok)
      this.setItems(await response.json)
    else
      console.log(response)
  }

  setItems(items) {
    this.clearItems()
    this.tomSelect.addOptions(items)
    this.tomSelect.addItem(this.selectedValue, true)
  }

  clearItems() {
    this.tomSelect.clear()
    this.tomSelect.clearOptions()
  }

  get tomSelect() {
    const create_text = this.createTextValue;
    const no_results_text = this.noResultsFoundTextValue;

    this._tomSelect ||= new TomSelect(this.element, {
      plugins: ['remove_button'],
      create: this.canCreateValue,
      render:{
        option_create: function( data, escape ){
          return '<div class="create">' + create_text + '<strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: function( data, escape ){
          return `<div class="no-results">${no_results_text}</div>`;
        },
      },
      maxOptions: 500
    })

    return this._tomSelect
  }
}
