import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {

  connect() {
    this.modal = new Modal(this.element, {
      keyboard: false
    });

    jQuery(ClientSideValidations.selectors.forms).validate();
    console.log($('form[data-client-side-validations]'));
    this.element.addEventListener('shown.bs.modal', function (event) {
      $('form[data-client-side-validations]').enableClientSideValidations();
    });

    this.element.addEventListener('hidden.bs.modal', function (event) {
      refreshConfirmables();
    });

    this.modal.show();
  }

  disconnect() {
    const elements = document.getElementsByClassName('modal-backdrop');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show();
    }
  }

  close() {
    this.modal.hide();
  }
}
