import { Controller} from "@hotwired/stimulus"

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
 function parseLocaleNumber(stringNumber, locale) {
    var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    );
}

export default class extends Controller {
  static targets = [ "quantity", "price", "vatRate", "vat", "total"]

  connect() {
    this.recalculate();
    this.recalculateTotals();
  }

  disconnect()
  {
    this.recalculateTotals();
  }

  recalculate() {
    if (!this.hasQuantityTarget || !this.hasPriceTarget) return;

    var formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      });

    var net = parseFloat(this.quantityTarget.value) * parseLocaleNumber(this.priceTarget.value, 'nl-NL');
    var vat = net * this.vatRateTarget.value;

    this.vatTarget.textContent = formatter.format(vat).replace(/\s/g,'');
    this.totalTarget.textContent = formatter.format(net + vat).replace(/\s/g,'');
  }

  recalculateTotals(){
    var row = document.getElementById("total-row");
    if(!row) return;
    var controller = this.application.getControllerForElementAndIdentifier(row, "job-shift-item-sum");
    if(!controller) return;
    controller.recalculate();
  }

  resetForm() {
    this.element.reset();
    this.recalculate();
  }
}


