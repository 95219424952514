import { Controller } from "@hotwired/stimulus"
import { createElement } from "react";
import { createRoot } from "react-dom/client";

export default class extends Controller {

  connect() {
    this.root = createRoot(this.element);
    //this.root.render(createElement(Checkbox));
  }

  disconnect() {
    this.root.unmount();
  }
}
