import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";
import "form-request-submit-polyfill";


export default class extends Controller {
  static values = {
    saveDelay: Number
  }

  initialize () {
    this.save = this.save.bind(this)
  }

  connect () {
    if (this.saveDelayValue > 0) {
      this.save = debounce(this.save, this.saveDelayValue)
    }
  }

  save () {
    if (window._rails_loaded) {
      Rails.fire(this.element, 'submit')
    } else {
      this.element.requestSubmit()
    }
  }

  reset () {
    this.element.reset();
  }
}


