import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import jQuery from "jquery"
import "@client-side-validations/client-side-validations/src"

export default class extends Controller {

  connect() {
    this.modal = new Modal(this.element, {
      keyboard: false
    });

    jQuery(ClientSideValidations.selectors.forms).validate();

    this.element.addEventListener('shown.bs.modal', function (event) {
      jQuery('form[data-client-side-validations]').enableClientSideValidations();
    });

    this.element.addEventListener('hidden.bs.modal', function (event) {
      var lastVisibleModal = Array.from(document.querySelectorAll('[data-modal-stack].opacity-0')).sort(function (a, b) {
        return a.dataset.modalStack - b.dataset.modalStack;
      }).pop();

      if (lastVisibleModal)
        lastVisibleModal.classList.remove('opacity-0');
    });

    var zIndexModal = 1055;

    this.element.addEventListener('show.bs.modal', function (event) {
      var lastVisibleModal = Array.from(document.querySelectorAll('[data-modal-stack]:not(.opacity-0)')).sort(function (a, b) {
        return a.dataset.modalStack - b.dataset.modalStack;
      }).pop();

      if (lastVisibleModal)
        lastVisibleModal.classList.add('opacity-0');

      var visibleModalsCount = document.querySelectorAll('.modal').length;
      var zIndex = zIndexModal + (100 * visibleModalsCount);

      event.target.style.zIndex = zIndex;
      event.target.dataset.modalStack = visibleModalsCount;
    });

    this.element.addEventListener('hidden.bs.modal', function (event) {
      refreshConfirmables();
    });

    this.modal.show();
  }

  disconnect() {
    /*const elements = document.getElementsByClassName('modal-backdrop');
    while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
    }*/
  }

  /* This hides the modal, waiting for fade-out bedore rendering next page. */
  hideBeforeRender(event) {
    event.preventDefault()
    this.element.addEventListener('hidden.bs.modal', event.detail.resume)
    this.modal.hide()
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show();
    }
  }

  close() {
    this.modal.hide();
  }
}
