import { Controller } from "@hotwired/stimulus"

import AirDatepicker from 'air-datepicker';
import localeNl from 'air-datepicker/locale/nl';

export default class extends Controller {

  connect(){
    new AirDatepicker(this.element, {
      locale: localeNl,
      firstDay: 1,
    });
  }

  disconnect(){ }
}
