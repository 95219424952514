import { Controller } from "@hotwired/stimulus"
import { MapboxAddressAutofill } from '@mapbox/search-js-web';
import { get } from "@rails/request.js"
import { set } from "lodash";
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    stateUrl: String
  }

  static targets = [
    "input",
    "expandButton",
    "resetButton",
    "hiddenFields",
    "countryField",
    "stateField"
  ]

  connect() {
    this.inputTarget.input.setAttribute("name", this.inputTarget.input.getAttribute("name").replace(' address-search', ''));
    this.countrySelect = new TomSelect(this.countryFieldTarget);
    this.stateSelect = new TomSelect(this.stateFieldTarget);

    this.countrySelect.on('change', (value) => {
      this.fetchStates();
    });

    this.inputTarget.addEventListener('retrieve', async (event) => {
      const featureCollection = event.detail;

      // Get the first feature from the results
      var feature = featureCollection.features[0];

      // Get the address properties from the feature
      var properties = feature.properties;

      // Assign each part of the address to a variable
      this.countrySelect.setValue(properties.country_code.toUpperCase(), true);

      this.fetchStates(properties.region_code.toUpperCase());
    });
  }

  disconnect() { }

  async fetchStates(set_state = "") {
    const response = await get(this.stateUrlValue, {
      query: { q: this.countrySelect.getValue() },
      responseKind: 'json'
    })

    if (response.ok)
      this.setStates(await response.json, set_state)
    else
      console.log(response)
  }

  setStates(states, set_state = "") {
    this.stateSelect.clear();
    this.stateSelect.clearOptions();
    this.stateSelect.addOptions(states);
    if (set_state != "") {
      this.stateSelect.setValue(set_state);
    }
    if (states.results == 0) {
      this.stateSelect.disable();
    } else {
      this.stateSelect.enable();
    }
  }

  expand() {
    this.expandButtonTarget.classList.add("d-none");
    this.hiddenFieldsTarget.classList.remove("d-none");
    //document.querySelector(".submit-btns").classList.remove("d-none");
  }

  reset() {
    this.expandButtonTarget.classList.remove("d-none");
    this.hiddenFieldsTarget.classList.add("d-none");
  }
}
