import { Controller} from "@hotwired/stimulus"
import { delegate, abnegate } from "jquery-events-to-dom-events"

export default class extends Controller {

  connect() {
    $(this.element).timepicker({
      className: "timepicker_fixed_width",
      appendTo: function($el) { return $el.parent() },
      timeFormat: 'H:i',
      closeOnWindowScroll: true
    });
  }

  disconnect(){
  }
}

  
  