import { Controller } from "@hotwired/stimulus";
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = ["input"]

  inputTargetConnected(target) {
    intlTelInput(target);
    console.log(`Hello,}!`);
  }

  connect() {
    this.test();

    //intlTelInput(this.element);
        // any initialisation options go here
    //
  }

  test()
  {
    //intlTelInput(this.element);
    console.log("huh");
  }
}


