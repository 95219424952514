import { Controller} from "@hotwired/stimulus"

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
 function parseLocaleCurrency(stringNumber, locale, currency) {
    var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');
    var currencySymbol = Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
    }).format(1).replace(/\p{Number}/gu, '').replace(new RegExp('\\' + decimalSeparator), '').trim();

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
        .replace(new RegExp('\\' + currencySymbol), '')
    );
}

function sumByClassName(className, locale, currency) {
  var formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  var rows = document.getElementsByClassName(className);
  var total = 0;
  for(var i = 0; i < rows.length; i++){
    total += parseLocaleCurrency(rows[i].textContent, locale, currency);
  }

  return formatter.format(total).replace(/\s/g,'');
}

export default class extends Controller {
  static targets = ["vat", "total"]

  connect() {
    this.recalculate();
  }

  recalculate() {
    this.vatTarget.textContent = sumByClassName('item-vat', 'nl-NL', 'EUR');
    this.totalTarget.textContent = sumByClassName('item-total', 'nl-NL', 'EUR');
  }
}


