import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bulkForm", "bulkActions", "checkboxAll", "checkbox"]

  connect() {

  }

  disconnect() {

  }

  bulkAction(event) {
    event.preventDefault()
    const button = event.target
    const form = this.bulkFormTarget
    // Check if any rows are selected
    const selectedRows = this.checkboxTargets.filter(cb => cb.checked)
    if (selectedRows.length === 0) {
      alert('Please select at least one Item.')
      return
    }
    // Set the form's action and method based on the button clicked
    form.action = button.getAttribute('formaction')
    form.method = button.getAttribute('formmethod') || 'post'

    // Submit the form
    form.requestSubmit()
  }

  initialize() {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.toggle)

    this.refresh()
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.toggle)

    this.refresh()
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.refresh)
    checkbox.addEventListener("change", this.updateRow)

    this.refresh()
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.refresh)
    checkbox.removeEventListener("change", this.updateRow)
    this.refresh()
  }

  toggle(e) {
    e.preventDefault()

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = e.target.checked

      var row = checkbox.closest('tr')

      if (row && checkbox.checked) {
        row.setAttribute('data-selected', 'true')
      } else {
        row.removeAttribute('data-selected')
      }

      this.triggerInputEvent(checkbox)
    })

    this.refresh();
  }

  updateRow(e) {
    e.preventDefault()

    var row = e.target.closest('tr')

    if (e.target.checked) {
      row.setAttribute('data-selected', 'true')
    } else {
      row.removeAttribute('data-selected')
    }
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length
    if (checkboxesCheckedCount > 0) {
      this.bulkActionsTarget.style.display = "block"
      this.checkboxAllTarget.checked = true
      this.checkboxAllTarget.indeterminate = checkboxesCheckedCount < checkboxesCount
    } else {
      this.bulkActionsTarget.style.display = "none"
      this.checkboxAllTarget.checked = false
      this.checkboxAllTarget.indeterminate = false
    }
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event)
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked() {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }
}
