import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect () {
  }

  toggle () {
    this.inputTargets.forEach(item => {
      item.disabled = !item.disabled;
    })
  }

  disable () {
    this.inputTargets.forEach(item => {
      item.disabled = true;
    })
  }

  enable () {
    this.inputTargets.forEach(item => {
      item.disabled = false;
    })
  }
}
