import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  connect() {
    Dropzone.autoDiscover = false;
    let myDropzone = new Dropzone(this.element);
    myDropzone.on("addedfile", file => {
      console.log(`File added: ${file.name}`);
    });
  }

  disconnect() {
  }
}

