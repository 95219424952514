import { Controller} from "@hotwired/stimulus"
import { delegate, abnegate } from "jquery-events-to-dom-events"


export default class extends Controller {

  connect() {
    if(this.element.dataset.delegate)
      this.delegate = delegate(this.element.dataset.delegate);
  }

  disconnect(){
    if(this.element.dataset.delegate)
      abnegate(this.element.dataset.delegate, this.delegate)
  }

  submitForm() {
    this.element.requestSubmit ? this.element.requestSubmit() : this.element.submit();
  }
}

  
  