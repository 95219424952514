import { Controller } from "@hotwired/stimulus";
import { get, post, put, patch, destroy } from "@rails/request.js";


import { Calendar } from '@fullcalendar/core';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';
import momentPlugin from '@fullcalendar/moment'
import { toMoment, toMomentDuration } from '@fullcalendar/moment';

export default class extends Controller {
  static targets = [ "modal", "calendar" ];

  static values = {
    events: String,
    baseUrl: String
  }

  connect() {
    let modal = this.modalTarget;
    let base_url = this.baseUrlValue;

    let calendar = new Calendar(this.calendarTarget, {
      plugins: [dayGridPlugin, bootstrap5Plugin, interactionPlugin, momentPlugin],
      initialView: 'dayGridMonth',
     // themeSystem: 'bootstrap5',
      eventDisplay: 'block',
      events: this.eventsValue,
      height: 'auto',
      firstDay: 1,
      allDaySlot: false,
      editable: true,
      eventStartEditable: true,
      displayEventEnd: true,
      locale: nlLocale,
      selectable: true,
      events: this.eventsValue,
    });

    window.addEventListener('load', () => {
      calendar.render();


    });
  }

  refresh(e) {
    if (e.detail.success) {
      calendar.refetchEvents();
    }
  }
}





/*
$("#orderShiftCalendar").fullCalendar({
  schedulerLicenseKey: "<%= Rails.configuration.fullcalendar_license %>",
  header: {
    left: "prev,next today",
    center: "title",
    right: "month,agendaWeek,listWeek"
  },
  events: "<%= shifts_customer_portal_path(format: :json, id: @customer.token) %>",
  defaultView: "agendaWeek",
  firstDay: 1,
  allDaySlot: false,
  editable: true,
  eventStartEditable:true,
  eventOverlap: false,
  displayEventEnd: true,
  //droppable: true,
  timeFormat: 'H:mm',
  locale: 'nl',

  themeSystem: 'digitalhospital',

  selectable: true,

  eventConstraint: {
    start: $.fullCalendar.moment().subtract(1, 'days'),
    end: $.fullCalendar.moment().startOf('month').add(400, 'month')
  },
  // eventAfterAllRender(event, element, view) {
  //   // make all events copyable using jQuery UI draggable
  //   $(".fc-event").each(function () {
  //     const $event = $(this);

  //     // store data so it can be copied in the drop function
  //     const event = $event.data("fcSeg").footprint.eventDef;
  //     $event.data("eventObj", event);

  //     // make the event draggable using jQuery UI
  //     $event.draggable({
  //       disabled: true,
  //       helper: "clone",
  //       revert: true,
  //       revertDuration: 0,
  //       zIndex: 999,
  //       stop(event, ui) {
  //         // when dragging of a copied event stops we must set them
  //         // copyable again if the control key is still held down
  //         if (ctrlIsPressed) {
  //           setEventsCopyable(true);
  //         }
  //       } });

  //   });
  // },
  drop: function (date, jsEvent, ui, resourceId) {
    const droppedEvent = $(this).data("eventObj");
    const origStartDate = droppedEvent.dateProfile.start;
    const origEndDate = droppedEvent.dateProfile.end;

    // Set the start date to the new date with the original time
    let startDate = moment(date);
    startDate.set({
      hour: origStartDate.get("hour"),
      minute: origStartDate.get("minute"),
      second: origStartDate.get("second") });

    const endDate = moment(date);

    // If the orginal dates were on different days we have to calculate the new end date
    if (!origStartDate.isSame(origEndDate, "d")) {
      endDate.add(
      droppedEvent.dateProfile.end.diff(
      droppedEvent.dateProfile.start,
      "d"),

      "d");

    }

    endDate.set({
      hour: origEndDate.get("hour"),
      minute: origEndDate.get("minute"),
      second: origEndDate.get("second") });


    var shiftStart = moment.tz(startDate, "YYYY-MM-DD HH:mm A", '<%= ActiveSupport::TimeZone::MAPPING[current_user.time_zone] %>');
    var shiftEnd = moment.tz(endDate, "YYYY-MM-DD HH:mm A", '<%= ActiveSupport::TimeZone::MAPPING[current_user.time_zone] %>');

    $.ajaxSetup({async: true});

    $.ajax({
      type: "POST",
      url: "<%= create_shift_customer_portal_path(token: @customer.token) %>",
      data: { shift: { start_time: shiftStart.toJSON(), end_time: shiftEnd.toJSON() } },
      success: function(data) {
        if(data.success)
        {
          var newShift = {
            id: data.shift.id,
            title: '',
            start: data.shift.start_time,
            end: data.shift.end_time
          };

          $("#orderShiftCalendar").fullCalendar( 'renderEvent', newShift );
        }
        else
        {
          toastr.error(data.error_message);
        }
      }
    });

  },

  eventClick: function (event, jsEvent, view)
  {
    updateShift = event;
    $('#update_shift_id').val(event.id);

    $('#update_shift_start_time').val(event.start.format("H:mm"));
    $('#update_shift_end_time').val(event.end.format("H:mm"));
    $('#update_shift_date').val(event.start.format("DD/MM/YYYY"));

    //$("#updateShiftModal").modal('show');






  },

  eventResize: function(event, delta, revertFunc) {

   // alert(event.end);
    $.ajaxSetup({async: true});

    $.ajax({
      type: "PATCH",
      url: "/customer_portals/<%= @customer.token %>/update_shift/" + event.id,
      data: { shift: { start_time: event.start.toJSON(), end_time: event.end.toJSON() }},
      success: function(data)
      {
        if(!data.success)
        {
          revertFunc();
        }
      }
    });

  },

  eventDrop: function(event, delta, revertFunc) {
    $.ajaxSetup({async: true});

    $.ajax({
      type: "PATCH",
      url: "/customer_portals/<%= @customer.token %>/update_shift/" + event.id,
      data: { shift: { start_time: event.start.toJSON(), end_time: event.end.toJSON() }},
      success: function(data)
      {
        if(!data.success)
        {
          revertFunc();
        }
      }
    });
  },

  select: function (start, end, jsEvent, view)
  {
    if(view.name == "month")
    {

      var selectionStart = start.clone();
      var selectionEnd = end.clone().subtract('days', 1);

      if(selectionStart.isSame(selectionEnd, 'day'))
      {
        $('#shift_date').val(selectionStart.format("DD/MM/YYYY"));
      }
      else
      {

        $("#recurring_range.input-daterange").data("datepicker").pickers[0].setDate(selectionStart.format("DD/MM/YYYY"));
        $("#recurring_range.input-daterange").data("datepicker").pickers[1].setDate(selectionEnd.format("DD/MM/YYYY"));

        $('#recurring_checkbox').prop("checked", true);
        $('#single_event_div').hide();
        $("#recurring_event_div").hide();
        $("#recurring_event_div").removeAttr('style');
      }
    }

    if(view.name == "agendaWeek")
    {

      $('#shift_start_time').val(start.format("H:mm"));
      $('#shift_end_time').val(end.format("H:mm"));

      $('#shift_date').val(start.format("DD/MM/YYYY"));
      $('#shift_end_date').val(end.format("DD/MM/YYYY"));
    }

    if(view.name == "agendaDay")
    {
      $('#shift_start_time').val(start.format("H:mm"));
      $('#shift_end_time').val(end.format("H:mm"));
      $('#shift_date').val(start.format("DD/MM/YYYY"));
    }

   // $("#addShiftModal").modal('show');

  },

  //selectOverlap: function(event) {
  //  return ! event.block;
  //}
});
 */
