import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    input: String,
    value: String
  }

  connect() {
  }

  set()
  {
    var inputElement = document.getElementById(this.inputValue);

    inputElement.value = this.valueValue;

    var event = new Event('input');
    inputElement.dispatchEvent(event);
  }
}
