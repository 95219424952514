import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    sortDirection: String,
    sortColumn: String,
    sortDirectionTarget: String,
    sortColumnTarget: String,
  }


  nextDirection()
  {
    if(this.sortDirectionValue == "" || this.sortDirectionValue == "desc")
      return "asc"
    else
      return "desc"
  }

  sortColumn()
  {
    var directionElement = document.getElementById(this.sortDirectionTargetValue);
    var columnElement = document.getElementById(this.sortColumnTargetValue);

    directionElement.value = this.nextDirection();
    columnElement.value = this.sortColumnValue;

    var event = new Event('input');
    directionElement.dispatchEvent(event);
    columnElement.dispatchEvent(event);
  }
}
